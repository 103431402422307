import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Loading } from '../LoadingScreen/Loading';
import { ResultBlock } from './ResultBlock/ResultBlock';
import { openInNewTab } from '../../utils/utils';
import { LANG_TEXT, TITLES } from '../../lang/community';
import { useLanguage } from '../../hooks/useLanguage';
// import { Modal } from '../Modal/Modal';
import { OutlinedButton2 } from '../OutlinedButton/OutlinedButton2';
import { RESULTS } from '../../lang/results';
import Taras from '../../assets/taras.png';
import Tarolog from '../../assets/tarolog.png';
import Selasemi from '../../assets/selasemi.png';
import Tey from '../../assets/tey.png';
import Kseniya from '../../assets/kseniya.png';
import Kotermack from '../../assets/kotermack.png';
import Pertsevart from '../../assets/pertseva.png';
import NN from '../../assets/nn_cool.png';
import St1nkers from '../../assets/st1nkers.png';
import Lexus from '../../assets/lexus.png';
import Olxndr from '../../assets/olxndr.png';

export const Results = () => {
	const language = useLanguage();
	const [showLoading, setShowLoading] = useState(true);
	// const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		document.title = TITLES[language].results;
	}, [language]);

	useEffect(() => {
		setTimeout(() => {
			setShowLoading(false);
		}, 2490);
	}, []);

	return (
		<>
			{/* <Modal isOpen={showModal} close={() => setShowModal(false)} /> */}
			<Loading showLoading={showLoading} />
			<ResultsWrap usePadding>
				<ResultsContent>
					<Heading>
						<h1>
							{LANG_TEXT[language].results.heading} <br /> GOSHAWTY CLUB
						</h1>
						<Divider />
						<p>{LANG_TEXT[language].results.info}</p>
						<p>{LANG_TEXT[language].results.info2}</p>
					</Heading>
					<List>
						{/* PIC */}
						{/* TARAS.S */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[0].username}
								text={RESULTS[0].text}
							/>
							<ImgWrap>
								<img draggable="false" src={Taras} alt="Illustration" />
							</ImgWrap>
						</SingleBlock>
						{/* PIC */}
						{/* FALLEN */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[2].username}
								text={RESULTS[2].text}
							/>
							<ImgWrap>
								<img draggable="false" src={Tarolog} alt="Illustration" />
							</ImgWrap>
						</SingleBlock>
						{/* PIC */}
						{/* TEY */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[4].username}
								text={RESULTS[4].text}
							/>
							<ImgWrap>
								<img draggable="false" src={Tey} alt="Illustration" />
							</ImgWrap>
						</SingleBlock>
						{/* PIC */}
						{/* KSENIYA0842 */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[5].username}
								text={RESULTS[5].text}
							/>
							<ImgWrap>
								<img draggable="false" src={Kseniya} alt="Illustration" />
							</ImgWrap>
						</SingleBlock>
						{/* PIC */}
						{/* KOTERMACK. */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[6].username}
								text={RESULTS[6].text}
							/>
							<ImgWrap>
								<img draggable="false" src={Kotermack} alt="Illustration" />
							</ImgWrap>
						</SingleBlock>
						{/* PIC */}
						{/* PERTSEVART */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[7].username}
								text={RESULTS[7].text}
							/>
							<ImgWrap>
								<img draggable="false" src={Pertsevart} alt="Illustration" />
							</ImgWrap>
						</SingleBlock>
						{/* INNA0000 */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[8].username}
								text={RESULTS[8].text}
							/>
						</SingleBlock>
						{/* PIC */}
						{/* NN__COOL */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[9].username}
								text={RESULTS[9].text}
							/>
							<ImgWrap>
								<img draggable="false" src={NN} alt="Illustration" />
							</ImgWrap>
						</SingleBlock>
						{/* BOBER#8888 */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[10].username}
								text={RESULTS[10].text}
							/>
						</SingleBlock>
						{/* PIC */}
						{/* SELASEMI */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[3].username}
								text={RESULTS[3].text}
							/>
							<ImgWrap>
								<img draggable="false" src={Selasemi} alt="Illustration" />
							</ImgWrap>
						</SingleBlock>
						{/* BU1BAZAWR */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[11].username}
								text={RESULTS[11].text}
							/>
						</SingleBlock>
						{/* LIEDETECTOR */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[12].username}
								text={RESULTS[12].text}
							/>
						</SingleBlock>
						{/* MIZLAW */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[13].username}
								text={RESULTS[13].text}
							/>
						</SingleBlock>
						{/* PIC */}
						{/* OLXNDR.FX */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[18].username}
								text={RESULTS[18].text}
							/>
							<ImgWrap>
								<img draggable="false" src={Olxndr} alt="Illustration" />
							</ImgWrap>
						</SingleBlock>
						{/* ARTMINDLER */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[14].username}
								text={RESULTS[14].text}
							/>
						</SingleBlock>
						{/* DOCTORFX */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[15].username}
								text={RESULTS[15].text}
							/>
						</SingleBlock>
						{/* PIC */}
						{/* ST1NKERS */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[16].username}
								text={RESULTS[16].text}
							/>
							<ImgWrap>
								<img draggable="false" src={St1nkers} alt="Illustration" />
							</ImgWrap>
						</SingleBlock>
						{/* ANDREWWW4948 */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[1].username}
								text={RESULTS[1].text}
							/>
						</SingleBlock>
						{/* PIC */}
						{/* LEXUS */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[17].username}
								text={RESULTS[17].text}
							/>
							<ImgWrap>
								<img draggable="false" src={Lexus} alt="Illustration" />
							</ImgWrap>
						</SingleBlock>
						{/* SFERIX */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[19].username}
								text={RESULTS[19].text}
							/>
						</SingleBlock>
						{/* LEGROUND */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[20].username}
								text={RESULTS[20].text}
							/>
						</SingleBlock>
						{/* DOCORD */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[21].username}
								text={RESULTS[21].text}
							/>
						</SingleBlock>
						{/* MaG */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[22].username}
								text={RESULTS[22].text}
							/>
						</SingleBlock>
						{/* Don */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[23].username}
								text={RESULTS[23].text}
							/>
						</SingleBlock>
						{/* aQlove */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[24].username}
								text={RESULTS[24].text}
							/>
						</SingleBlock>
						{/* Denny */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[25].username}
								text={RESULTS[25].text}
							/>
						</SingleBlock>
						{/* F1rst */}
						<SingleBlock>
							<ResultBlock
								username={RESULTS[26].username}
								text={RESULTS[26].text}
							/>
						</SingleBlock>
					</List>
					<ButtonWrap>
						<OutlinedButton2
							isWhite
							text={LANG_TEXT[language].results.next}
							onClick={() => openInNewTab('https://t.me/gsclub_manager')}
						/>
					</ButtonWrap>
				</ResultsContent>
			</ResultsWrap>
		</>
	);
};

const ButtonWrap = styled.div`
	margin-top: 70px;
`;

const ResultsWrap = styled.div<{
	usePadding?: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-top: ${({ usePadding }) => usePadding && '130px'};
	background: ${(p) => p.theme.blackBg};

	@media (max-width: 950px) {
		padding-top: ${({ usePadding }) => usePadding && '100px'};
	}

	* {
		color: ${(p) => p.theme.whiteText};
	}
`;

const ResultsContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: ${(p) => p.theme.screenWidthSmall};
	padding: 20px 15px 70px;
`;

const Divider = styled.div`
	margin-top: 30px;
	height: 2px;
	width: 50%;
	background: ${({ theme }) => theme.whiteText};
`;

const Heading = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	h1 {
		font-size: 70px;
		text-align: center;

		@media (max-width: 870px) {
			font-size: 60px;
		}

		@media (max-width: 750px) {
			font-size: 50px;
		}

		@media (max-width: 630px) {
			font-size: 40px;
		}

		@media (max-width: 515px) {
			font-size: 30px;
		}

		@media (max-width: 515px) {
			font-size: 27px;
		}
	}

	p {
		text-align: center;
		text-transform: uppercase;
		font-size: 12px;
		color: #444444;

		&:nth-of-type(1) {
			margin-top: 30px;
		}
	}
`;

const List = styled.div`
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	width: 100%;

	> div {
		margin-top: 80px;
	}
`;

const ImgWrap = styled.div`
	width: 40%;
	padding-left: 30px;

	> img {
		width: 100%;
	}

	&#small {
		width: 30%;
	}

	@media (max-width: 1170px) {
		width: 80%;

		&#small {
			width: 40%;
		}
	}

	@media (max-width: 880px) {
		width: 100%;
	}

	@media (max-width: 750px) {
		padding: 35px 50px;

		&#small {
			padding: 50px 50px 35px;
			width: 60%;
		}
	}

	@media (max-width: 600px) {
		padding: 35px 25px;

		&#small {
			padding: 35px 25px;
			width: 80%;
		}
	}

	@media (max-width: 450px) {
		padding: 35px 10px;

		&#small {
			padding: 35px 10px;
			width: 100%;
		}
	}
`;

const SingleBlock = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 750px) {
		justify-content: center;
		flex-direction: column;
	}
`;
