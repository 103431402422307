import React, { useEffect, useState } from 'react';
import { useLanguage } from '../../hooks/useLanguage';
import { LANG_TEXT, TITLES } from '../../lang/community';
import { Loading } from '../LoadingScreen/Loading';
import styled from 'styled-components';
import { OutlinedButton } from '../OutlinedButton/OutlinedButton';
import { openInNewTab } from '../../utils/utils';
import Ind1 from '../../assets/indicators/calculator.png';
import Ind2 from '../../assets/indicators/forex.png';
import Ind3 from '../../assets/indicators/ultimate.png';
import Ind4 from '../../assets/indicators/index.png';
import Ind5 from '../../assets/indicators/ranges.png';
import Ind6 from '../../assets/indicators/watermark.png';

const getImage = (id: number) => {
	if (id === 1) return Ind1;
	if (id === 2) return Ind2;
	if (id === 3) return Ind3;
	if (id === 4) return Ind4;
	if (id === 5) return Ind5;
	if (id === 6) return Ind6;
};

export const Indicators = () => {
	const language = useLanguage();
	const [showLoading, setShowLoading] = useState(true);

	useEffect(() => {
		document.title = TITLES[language].indicators;
	}, [language]);

	useEffect(() => {
		setTimeout(() => {
			setShowLoading(false);
		}, 2490);
	}, []);

	return (
		<>
			<Loading showLoading={showLoading} />
			<IndicatorsWrapper usePadding>
				<IndicatorsContent>
					<Heading>
						<h1>{LANG_TEXT[language].indicators.heading}</h1>
						<Divider />
						<p>{LANG_TEXT[language].indicators.subheading}</p>
					</Heading>
					<List>
						{LANG_TEXT[language].indicators.list.map((indicator) => (
							<Card>
								<CardImage onClick={() => openInNewTab(indicator.scriptURL)}>
									<img src={getImage(indicator.id)} alt="indicator" />
								</CardImage>
								<CardInfo>
									<h1>{indicator.name}</h1>
									<h2>{indicator.shortInfo}</h2>
									<DividerSmall />
									<p>{indicator.longInfo}</p>
								</CardInfo>
								<CardControls>
									<OutlinedButton
										text={LANG_TEXT[language].indicators.add}
										to={indicator.scriptURL}
										isWhite
										isSmall
									/>
									{indicator.guideURL && (
										<OutlinedButton
											text={LANG_TEXT[language].indicators.guide}
											to={indicator.guideURL}
											isWhite
											isSmall
										/>
									)}
								</CardControls>
							</Card>
						))}
					</List>
				</IndicatorsContent>
			</IndicatorsWrapper>
		</>
	);
};

const IndicatorsWrapper = styled.div<{
	usePadding?: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-top: ${({ usePadding }) => usePadding && '130px'};
	background: ${(p) => p.theme.blackBg};

	@media (max-width: 950px) {
		padding-top: ${({ usePadding }) => usePadding && '100px'};
	}

	* {
		color: ${(p) => p.theme.whiteText};
	}
`;

const IndicatorsContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: ${(p) => p.theme.screenWidthSmall};
	padding: 20px 15px 70px;
`;

const Heading = styled.div`
	margin-top: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	h1 {
		font-size: 70px;
		text-align: center;

		@media (max-width: 870px) {
			font-size: 60px;
		}

		@media (max-width: 750px) {
			font-size: 50px;
		}

		@media (max-width: 630px) {
			font-size: 40px;
		}

		@media (max-width: 515px) {
			font-size: 30px;
		}

		@media (max-width: 515px) {
			font-size: 27px;
		}
	}

	p {
		text-align: center;
		text-transform: uppercase;
		font-size: 12px;
		color: #444444;

		&:nth-of-type(1) {
			margin-top: 30px;
		}
	}
`;

const Divider = styled.div`
	margin-top: 30px;
	height: 2px;
	width: 50%;
	background: ${({ theme }) => theme.whiteText};
`;

const DividerSmall = styled.div`
	margin: 20px 0;
	height: 2px;
	width: 150px;
	background: ${({ theme }) => theme.whiteText};
`;

const List = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-bottom: 100px;

	@media (max-width: 1069px) {
		flex-direction: column;
		align-items: center;
	}
`;

const Card = styled.div`
	width: 50%;
	margin-top: 100px;
	max-width: 500px;
	display: flex;
	flex-direction: column;

	@media (max-width: 1069px) {
		width: 100%;
	}

	&:hover {
		img {
			filter: grayscale(0%) brightness(100%);
		}
	}
`;

const CardImage = styled.div`
	width: 100%;
	cursor: pointer;

	img {
		width: 100%;
		filter: grayscale(100%) brightness(80%);
		transition: 0.2s;
	}
`;

const CardInfo = styled.div`
	margin-top: 25px;

	* {
		user-select: none;
	}

	h1 {
		font-weight: normal;
	}

	h2 {
		margin-top: 10px;
		font-weight: bold;
		text-transform: uppercase;
	}

	p {
		font-size: 16px;
	}
`;

const CardControls = styled.div`
	display: flex;
	margin-top: 25px;

	> a:nth-of-type(2) {
		margin-left: 20px;
	}

	@media (max-width: 445px) {
		flex-direction: column;

		> a:nth-of-type(2) {
			margin-left: 0px;
			margin-top: 15px;
		}
	}
`;
